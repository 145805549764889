import "../Assets/CSS/Copyright.css";
import { FaLinkedin } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaFacebookSquare } from "react-icons/fa";
import { RiTwitterXFill } from "react-icons/ri";
import { FaWhatsapp } from "react-icons/fa";
import { FaTelegramPlane } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
const Copyright = () => {
  return (
    <>
      <div className="copy_wrapper">
      <div class="desclamer_div">
      <span className="desclamer_tilte">
          © Copyright 2025 Mk e-tech Private Limited | All Rights Reserved.
          Privacy | Disclaimer
        </span>
      </div>
        <br></br>
        <div className="icso ">
          <span class ="lpm"><FaLinkedin/></span>
          <span class ="lpm"><RiTwitterXFill/></span>
          <span class ="lpm"><FaInstagramSquare/></span>
          <span class ="lpm"><FaFacebookSquare /></span>
          <span class ="lpm"><FaWhatsapp/></span>
          <span class ="lpm"><FaTelegramPlane/></span>
          <span class ="lpm"><FaYoutube/></span>
        </div>
      </div>
    </>
  );
};
export default Copyright;
