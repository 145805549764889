import videoBg from "../Assets/Videos/mito.mp4";
import "../Assets/CSS/Home.css";
import Industries from "./Industries";
import Technology from "./Technology";
import About from "./About";
import Assessment from "./Assessment";
// import Together from "./Together";
import Footer from "./Footer";
import Copyright from "./Copyright";
// import { FaPhoneAlt } from "react-icons/fa";
// import { BsWhatsapp } from "react-icons/bs";
import { Link } from "react-router-dom";

const Home=()=>{
    return(
        <>
        <div className="main">
            <video src={videoBg} autoPlay loop muted/>
            <div className="contents">
                <h1>We are a Global Digital Transformation
                Partner to Successful
                Enterprises
                </h1>
            <Link to="/contact"><button className="btn_exp">EXPLORE MORE</button></Link>
            </div>
        </div>
        
      <div className="iconss">
        <div className="faw">
        <h3 className="enus"><Link to="/contactus" className="enqiure_btn">Enquire Now</Link> </h3>
        </div>
      </div>
        <Industries/>
        <Technology/>
        <About/>
        <Assessment/>
        {/* <Together/> */}
        <Footer/>
        <Copyright/>
        </>
    )
}
export default Home