import "../Assets/CSS/Industries.css";
import fintech from "../Assets/Images/Logoes/fintech.png";
import realestate from "../Assets/Images/Icons/real_estates.webp";
import energy from "../Assets/Images/Icons/energy.png";
import banking from "../Assets/Images/Icons/bankings.jpg";
import pharma from "../Assets/Images/Logoes/pharma.png";
import healthcare from "../Assets/Images/Logoes/healthcare.png";
import welfare from "../Assets/Images/Logoes/welfare.png";
import art from "../Assets/Images/Logoes/art.png";
import insurance from "../Assets/Images/Logoes/insurance.png";
import government from "../Assets/Images/Logoes/govern.png";
import Entertainment from "../Assets/Images/Logoes/entertainment.png";
import gaming from "../Assets/Images/Icons/gamings.png";


const Industries = () => {
  return (
    <>
      <div className="Industries_wrapper">
        <h1 className="we_are">Industries We Specialize In</h1>

        {/* boxes start */}
        <div className="card_wrapper">
        <div className="cards">
            <img src={fintech} height={30} />
            <h1 className="serive_title">Fintech</h1>
        </div>
        <div className="cards">
            <img src={realestate} />
            <h1 className="serive_title">Real Estate</h1>
        </div>
        <div className="cards">
            <img src={energy} />
            <h1 className="serive_title">Energy</h1>
        </div>
        <div className="cards">
            <img src={banking} />
            <h1 className="serive_title">Banking</h1>
        </div>
        <div className="cards">
            <img src={pharma} />
            <h1 className="serive_title">Pharma</h1>
        </div>
        <div className="cards">
            <img src={healthcare} />
            <h1 className="serive_title">Healthcare</h1>
        </div>
        <div className="cards">
            <img src={welfare} />
            <h1 className="serive_title">Welfare</h1>
        </div>
        <div className="cards">
            <img src={art} />
            <h1 className="serive_title">Art</h1>
        </div>
        <div className="cards">
            <img src={insurance} />
            <h1 className="serive_title">Insurance</h1>
        </div>
        <div className="cards">
            <img src={government} />
            <h1 className="serive_title">Government</h1>
        </div>
        <div className="cards">
            <img src={Entertainment} />
            <h1 className="serive_title">Entertainment</h1>
        </div>
        <div className="cards">
            <img src={gaming} />
            <h1 className="serive_title">Gaming</h1>
        </div>
        </div>
      </div>
    </>
  );
};
export default Industries;
