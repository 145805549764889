import React from 'react';
import "../Assets/CSS/Contact1.css";
import ContactImage from "../Assets/Images/contact.jpg"; 
import Footer from "./Footer";
import Copyright from "./Copyright";
import Certificate1 from "../Assets/Images/Certificate1.jpg";
import Certificate2 from "../Assets/Images/Certificate2.jpg";


// interface LocationData {
//     title: string
//     address: string[]
//   }
  
  const locations = [
    {
      title: "Registered Office",
      address: ["N-9 , A-116, L-104/2 Shiva, Cidco Aurangabad,", "Maharashtra, 431001."],
      email : "info@mketech.in"
    },
    {
      title: "Corporate Office",
      address: [
        "B-13, 2nd Floor, Prozone Trade Center, beside Prozone",
        "Mall, MIDC Industrial Area, Chilkalthana, Aurangabad,",
        "Maharashtra 431003",
      ],
      email : "info@mketech.in"
    },
    {
      title: "Pune",
      address: [
        "Blue Ridge Township, Phase II,",
        "Near Rajiv Gandhi Infotech Park - Phase I,",
        "Hinjawadi, India, Pune, 411057",
      ],
      email : "pune@mketech.co"
    },
    {
      title: "Bengaluru",
      address: ["S End C Cross Rd, KSRTC Layout,", "3 nd Phase, JP Nagar, Bengaluru,", "Karnataka 560078"],
      email : "help@mketech.co"
    },
    {
      title: "Hyderabad",
      address: ["Admin Block, Mariner Bldg", "The V Plot No. 17, Software Units Layout", "Madhapur Hyderabad 500081"],
      email : "help@mketech.co"
    },
    {
      title: "Chennai",
      address: [
        "5 st Floor, Pinnacle Building",
        "International Tech Park Chennai",
        "(ITPC) Taramani Road Chennai-600113",
      ],
      email : "help@mketech.co"
    },
    {
      title: "Vietnam",
      address: ["29th Floor East Tower", "54 Lieu Giai", "Ba Dinh District, Vietnam 10149"],
      email : "help@mketech.co"
    },
    {
      title: "New York",
      address: ["295 Madison Ave", "12th Floor", "New York City, NY 10017"],
      email : "help@mketech.co"
    },
    {
      title: "Sydney",
      address: ["680 George Street", "Level 45, Haymarket", "Sydney, Australia 2000"],
      email : "help@mketech.co"
    },
  ]

const Contact1 = () => {
  return (
    <div className='wrapper_div'>
      <div className="main_container">
        {/* Left Side - Contact Form */}
        <div className="left_container">
          <h1 className='contact_heading'>Contact Us</h1>
          <form action="#" className="contact_form">
            <label>First Name</label>
            <input type="text" name="first_name" required />
            
            <label>Last Name</label>
            <input type="text" name="last_name" required />
            
            <label>Phone Number</label>
            <input type="text" name="phone_number" required />
            
            <span className ="email_label">Email</span>
            <input type="email" name="email" required />
            
            <button type="submit">Submit</button>
          </form>
        </div>
        
        {/* Right Side - Image */}
        <div className="right_container">
          <img src={ContactImage} alt="Contact Us" className="contact_image" />
        </div>
      </div>


      {/* Global Services Container Starts From Here */}

      <div className="global-services-container" id="locations">
      
      <div className="global_services_heading">
        <h1 className = "Global_presence">Our Global Presence</h1>
      </div>

      <div className="locations-container">
      <div className="locations-grid">
        {locations.map((location, index) => (
          <div key={index} className="location-card">
            <h2 className="location-title">{location.title}</h2>
            <div className="location-address">
              {location.address.map((line, lineIndex) => (
                <p key={lineIndex}>{line}</p>
              ))}
              <span className='email_bold_location'>Email: {location.email}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
    
     </div>


            {/* Iso Certificate Container Starts from Here */}

    <div className="Iso_container">
    <div className ="ISO Certified">
          <h1 className='Iso_heading'>ISO Certified Certification</h1>
      </div>
        
      <div className="iso-div-certification">
      <div className="iso-container">
          <div className="iso-card">
        <img src={Certificate1} alt="Certificate1" className="certificate_image" />
        <h3 className="iso-card-title">ISO 9001:2015 (QMS)</h3>
      </div>
          <div className="iso-card">
        <img src={Certificate2} alt="Certificate2" className="certificate_image" />
        <h3 className="iso-card-title">Certificate of Compliance</h3>
      </div>
          </div>
      </div>     
      <Footer/>
      <Copyright/>
      <div className="iconss">
        <div className="faw">
           <h3 className="enus">Enquire Now</h3>
        </div>
      </div>
    </div>
     
    </div>

  );
};

export default Contact1;



