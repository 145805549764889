import "./App.css";
import Navbar from "./Components/Navbar";
import Home from "./Components/Home";
// import Contact from "./Components/Contact";
import { BrowserRouter, Routes, Route,useLocation } from "react-router-dom";
import Preloader from "./Components/Preloader";
import { useEffect,useState } from "react";
import Contact1 from "./Components/Contact1";

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setFadeOut(true);
      setTimeout(() => setIsLoading(false), 500); // Match the duration of the fade-out transition
    }, 3000); // Adjust this duration based on your needs
  }, []);
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        {isLoading && <Preloader className={fadeOut ? 'fade-out' : ''} />}
        {!isLoading && (
        <Routes>
         <Route path="/" element={<Home />} />
          <Route path="/contactus" element={<Contact1 />} />
        </Routes>
         )}
      </BrowserRouter>
  
    </div>
  );
}

export default App;
