import Cards from "./TechnolgyJson"
import "../Assets/CSS/Technology.css";
const Technology=()=>{
    return(
        <>
        <div id="technolgy_wrapper">
           <div className="tech_pracv">
           Technology Practices
           </div>
        <div className="card_flex">
            {
                Cards.map((vc)=>(
                    <div className="main_card_ui">
                     <img src={vc.img} className="tech_imgs"/>
                     <h3 className="tech_tiltle">{vc.Names}</h3>
                     <button className="tech_nis">{vc.btn}</button>
                    </div>
                ))
            }
        </div>
        </div>
        </>
    )
}
export default Technology